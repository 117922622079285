import axios, { AxiosResponse } from 'axios';
import { VariantModel, VariantResult } from '@/models/Variant';
import { CbxVariablesModule } from '@/store/modules/CbxVariablesModule';

export default class VariantService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/variant`;
    }

    public async getVariants(id: string = "", onlyFromCategory: boolean = false): Promise<VariantResult> {
        const currentCategoryId = CbxVariablesModule.VARIABLES.currentCategoryId;
        let requestUrl = `${this.endpoint}/id?id=${id}`;
        if (onlyFromCategory) {
            requestUrl += `&category=${currentCategoryId}`;
        }
        if (id.length === 0) return null;
        try {
            const res: AxiosResponse<VariantResult> = await axios.get(requestUrl);
            return res.data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    public async getVariantsByBulkGroup(bulkgroup: string = ''): Promise<VariantModel[]> {
        if (bulkgroup.length === 0) return [];
        try {
            const res: AxiosResponse<VariantResult> = await axios.get(
                `${this.endpoint}/bulkgroup?bulkgroup=${bulkgroup}`
            );
            return res.data.variants;
        } catch (e) {
            console.log(e);
            return [];
        }
    }
}

import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import CartService from '@/services/CartService';
import {
    WebCart,
    AddOrUpdateCartEditModel,
    WebCartItemsLookup,
    AddToCartModalConfig,
    WebCartCurrentDelivery,
    WebCartCurrentFreeGift,
    BackOrderInfo,
} from '@/models/Cart';
import LoadingModule from './LoadingModule';
import FreeGiftService from '@/services/FreeGiftService';

@Module({
    store,
    dynamic: true,
    name: 'cart',
    namespaced: true,
})
class CartModule extends VuexModule {
    private cs: CartService = new CartService();
    private fgs: FreeGiftService = new FreeGiftService();

    private cart: WebCart = this.cs.getCartModel();
    private cartLookup: WebCartItemsLookup = this.cs.getCartItemLookupModel();
    private addToCartModal: AddToCartModalConfig = this.cs.getAddToCartModalConfig();
    private isOpen: boolean = false;
    private cartLoaded: boolean = false;
    private cartCurrentDeliveryLoaded: boolean = false;
    private cartCurrentDelivery: WebCartCurrentDelivery = this.cs.getCartCurrentDeliveryModel();
    private cartCurrentFreeGift: WebCartCurrentFreeGift = this.fgs.getCartCurrentFreeGiftModel();
    private reselectCartCurrectFreeGift: boolean = false;
    private backOrderInfo: BackOrderInfo = this.cs.getBackOrderInfoConfig();

    get CART(): WebCart {
        return this.cart;
    }

    get CART_CURRENT_DELIVERY(): WebCartCurrentDelivery {
        return this.cartCurrentDelivery;
    }

    get CART_CURRENT_FREE_GIFT(): WebCartCurrentFreeGift {
        return this.cartCurrentFreeGift;
    }

    get CART_LOOKUP(): WebCartItemsLookup {
        return this.cartLookup;
    }

    get IS_OPEN(): boolean {
        return this.isOpen;
    }

    get CART_LOADED(): boolean {
        return this.cartLoaded;
    }

    get CART_CURRENT_DELIVERY_LOADED(): boolean {
        return this.cartCurrentDeliveryLoaded;
    }

    get ADD_TO_CART_MODAL(): AddToCartModalConfig {
        return this.addToCartModal;
    }

    get RESELECT_CART_CURRENT_FREE_GIFT_GIFT(): boolean {
        return this.reselectCartCurrectFreeGift;
    }
    
    get BACK_ORDER_INFO(): BackOrderInfo {
        return this.backOrderInfo;
    }

    @Action({ commit: 'SET_CART' })
    public async GET_CART() {
        LoadingModule.SET_LOADING(true);
        return await this.cs.getCart();
    }

    @Action({ commit: 'SET_CART' })
    public async ADD_TO_CART(args: AddOrUpdateCartEditModel) {
        LoadingModule.SET_LOADING(true);
        return await this.cs.addOrUpdateCart(args);
    }

    @Action({ commit: 'SET_CART' })
    public async ADD_LIST_TO_CART(args: AddOrUpdateCartEditModel[]) {
        LoadingModule.SET_LOADING(true);
        return await this.cs.addOrUpdateCartFromList(args);
    }

    @Action({ commit: 'SET_CART_CURRENT_DELIVERY' })
    public async GET_CART_CURRENT_DELIVERY() {
        LoadingModule.SET_LOADING(true);
        return await this.cs.getCartCurrentDelivery();
    }

    @Action
    public async ADD_CART_CURRENT_FREE_GIFT(payload: string) {
        LoadingModule.SET_LOADING(true);
        return await this.fgs.add(payload);
    }
    @Action
    public async REMOVE_CART_CURRENT_FREE_GIFT() {
        LoadingModule.SET_LOADING(true);
        return await this.fgs.remove();
    }

    @Action({ commit: 'SET_CART_CURRENT_FREE_GIFT' })
    public async GET_CART_CURRENT_FREE_GIFT() {
        LoadingModule.SET_LOADING(true);
        return await this.fgs.getCurrent();
    }

    @Action
    public async CHANGE_CART(merge: boolean): Promise<boolean> {
        return await this.cs.changeCart(merge);
    }

    @Action({ commit: 'SET_BACK_ORDER_INFO' })
    public async GET_BACK_ORDER_INFO() {
        LoadingModule.SET_LOADING(true);
        return await this.cs.getBackOrderInfo();
    }

    @Mutation
    public SET_CART(newcart: WebCart) {
        this.cart = newcart;
        this.cartLookup = this.cs.createLookup(newcart);
        this.cartLoaded = true;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_CART_CURRENT_DELIVERY(args: WebCartCurrentDelivery) {
        this.cartCurrentDelivery = args;
        this.cartCurrentDeliveryLoaded = true;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_CART_CURRENT_FREE_GIFT(args: WebCartCurrentFreeGift) {
        this.cartCurrentFreeGift = args;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_RESELECT_CART_CURRENT_FREE_GIFT(args: boolean) {
        this.reselectCartCurrectFreeGift = args;
        LoadingModule.SET_LOADING(false);
    }

    @Mutation
    public SET_IS_OPEN(args: boolean) {
        this.isOpen = args;
    }

    @Mutation
    public SET_ADD_TO_CART_MODAL(args: AddToCartModalConfig) {
        this.addToCartModal = { ...this.addToCartModal, ...args };
    }

    @Mutation
    public SET_BACK_ORDER_INFO(payload: BackOrderInfo) {
        this.backOrderInfo = payload;
        LoadingModule.SET_LOADING(false);
    }
}

export default getModule(CartModule);
